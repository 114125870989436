import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Button,
  Row,
  Col,
  Card,
  Input,
  Select,
  Spin,
  Alert,
  Modal,
  Typography
} from "antd";
import { SaveOutlined, SearchOutlined } from "@ant-design/icons";
import { Header, InputCurrency, InputMask, Form } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { TRANSFER_STATUS_DATA } from "utils/static-data";
import { searchCnpj } from "utils";
import { getRefundById, saveRefund } from "services/refund-service";
import {
  getCompanyBenefits,
  getCompanyByDocument
} from "services/company-service";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

import moment from "moment";
import { DatePicker } from "components";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const RefundDetailsScreen = (props) => {
  const { login } = props;
  const { id = null } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [benefits, setBenefits] = React.useState([]);
  const [fullImage, setFullImage] = React.useState(false);
  const [canEdit, setCanEdit] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [textSearch, setTextSearch] = React.useState("");

  const load = async () => {
    setLoading(true);
    setBenefits(await getCompanyBenefits());
    if (id) {
      const res = await getRefundById(id);
      if (res) {
        setData(res);
      }
    }
    setLoading(false);
  };

  const loadFields = () => {
    const refund = data.refund;
    form.setFieldsValue({
      ...refund,
      createdAt: moment(refund.createdAt),
      dateNF: refund.dateNF ? moment(refund.dateNF) : null,
      status:
        login.company.master ||
        refund.status.code === "a" ||
        refund.status.code === "d"
          ? refund.status.code
          : null,
      typeRefund: refund.corporate ? "CORPORATIVO" : "COLABORADOR",
      companies: refund.companies.map((company) => company.name).join(", ")
    });
    setCanEdit(data.canEdit);
  };

  const onSave = async (values) => {
    setLoading(true);
    try {
      await saveRefund(id, values, data?.refund.corporate);
      navigate("/refund");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onShowSearch = (show) => {
    setShowSearch(show);
    if (show) {
      setTimeout(() => {
        searchCnpj().open();
      }, 1000);
    }
  };

  const onParseSearch = () => {
    const result = searchCnpj().parse(textSearch);
    form.setFieldsValue({
      cnpj: result.cnpj,
      razaoSocial: result.name,
      nomeFantasia: result.fantasyname,
      cnae: result.cnae
    });
    onShowSearch(false);
  };

  const searchCompany = async () => {
    let companiesList = await getCompanyByDocument(
      form
        .getFieldValue("cnpj")
        .replace(/\./g, "")
        .replace(/\-/g, "")
        .replace(/\//g, "")
    );
    if (companiesList.length > 0) {
      form.setFieldsValue({
        cnpj: companiesList[0].cnpj,
        razaoSocial: companiesList[0].corporateName,
        nomeFantasia: companiesList[0].fantasyName,
        cnae: companiesList[0].cnae
      });
    } else {
      onShowSearch(true);
    }
  };

  React.useEffect(() => {
    if (data) {
      loadFields();
    }
  }, [data]);

  React.useEffect(() => {
    load();
  }, [id]);

  return (
    <Layout className="alymente-layout">
      <Header
        title={`Reembolso ${id}${data && data.refund.employee ? " - " + data.refund.employee : ""}`}
        subtitle="Preencha os dados de validação e selecione um status"
      >
        {login.company.master && data?.refund.corporate && (
          <Alert message="Reembolso Corporativo" />
        )}

        {data &&
        data.refund.corporate &&
        data.refund.spendingLimit &&
        data.refund.spendingLimit > 0 &&
        data.value > data.refund.spendingLimit ? (
          <Alert
            message="Valor acima do estabelecido na política da empresa para esse tipo de despesa. Por favor, verifique se é permitido fazer a aprovação."
            type="warning"
          />
        ) : null}
      </Header>
      <Content>
        <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
          <Row justify="center" gutter={24}>
            <Col xl={12} lg={12} md={24}>
              <Card title="Solicitação">
                <Spin spinning={loading}>
                  <Row gutter={24}>
                    {!fullImage && (
                      <React.Fragment>
                        <Col lg={12} md={24}>
                          <Form.Item label="Valor Solicitado" name={"value"}>
                            <InputCurrency disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item label="Data do Pedido" name={"createdAt"}>
                            <DatePicker showTime disabled />
                          </Form.Item>
                        </Col>

                        {data?.refund.corporate ? (
                          <>
                            <Col lg={12} md={24}>
                              <Form.Item
                                label="Tipo de Despesa"
                                name={"expenseType"}
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>

                            <Col lg={12} md={24}>
                              <Form.Item
                                label="Limite de Gasto"
                                name={"spendingLimit"}
                              >
                                <InputCurrency disabled />
                              </Form.Item>
                            </Col>
                          </>
                        ) : (
                          <Col lg={12} md={24}>
                            <Form.Item label="Benefício" name={"expenseType"}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}

                        <Col lg={12} md={24}>
                          <Form.Item label="Empresa" name={"employeeCompany"}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item label="Opção" name={["option", "name"]}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        {login.company.master && (
                          <Col lg={12} md={24}>
                            <Form.Item label="Tipo" name={"typeRefund"}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                        <Col md={24}>
                          <Form.Item label="Descrição" name={"description"}>
                            <Input.TextArea
                              disabled
                              rows={3}
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        {login.company.selectCompanyGroupRefund && (
                          <Col md={24}>
                            <Form.Item
                              label="Empresa(s) vínculada(s) ao Reembolso"
                              name={"companies"}
                            >
                              <Input.TextArea
                                disabled
                                rows={3}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                        )}
                        <Col md={24}>
                          <Form.Item label="QRCode" name={"qrcode"}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={24}>
                          <Form.Item label="Texto Nota Fiscal" name={"text"}>
                            <Input.TextArea
                              disabled
                              rows={3}
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                      </React.Fragment>
                    )}
                    {data && data.imageRefund && (
                      <React.Fragment>
                        <Col md={24} style={{ textAlign: "center" }}>
                          <Button
                            type="link"
                            onClick={() => setFullImage((prev) => !prev)}
                          >
                            {fullImage ? "Diminuir Imagem" : "Expandir Imagem"}
                          </Button>
                        </Col>
                        <Col md={24} style={{ textAlign: "center" }}>
                          {fullImage ? (
                            <InnerImageZoom
                              width={500}
                              src={data.imageRefund}
                              hideHint={true}
                              moveType="drag"
                              hideCloseButton={true}
                            />
                          ) : (
                            <img
                              alt="image"
                              src={data.imageRefund}
                              width={200}
                            />
                          )}
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                </Spin>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={24}>
              <Card title="Validação">
                <Spin spinning={loading}>
                  <Row gutter={24}>
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Valor Aprovado"
                        name="valueNF"
                        rules={[
                          {
                            type: "number",
                            required: true,
                            message: "Insira o valor aprovado"
                          }
                        ]}
                      >
                        <InputCurrency disabled={!canEdit} autoComplete="off" />
                      </Form.Item>
                    </Col>
                    {login.company.master && (
                      <Col lg={12} md={24}>
                        <Form.Item label="Taxa" name="fee">
                          <InputCurrency disabled autoComplete="off" />
                        </Form.Item>
                      </Col>
                    )}
                    {data && !data.refund.corporate && (
                      <Col lg={12} md={24}>
                        <Form.Item
                          label="Benefício"
                          name={"benefitCNAE"}
                          rules={[
                            { required: false, message: "Insira um benefício" }
                          ]}
                        >
                          <Select
                            disabled={!canEdit}
                            placeholder="Selecione um benefício"
                            allowClear
                          >
                            {benefits.map((x) => (
                              <Select.Option value={x.benefit}>
                                {x.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Status"
                        name={"status"}
                        rules={[
                          { required: true, message: "Insira um status" }
                        ]}
                      >
                        <Select
                          disabled={!canEdit}
                          placeholder="Selecione um status"
                          allowClear
                        >
                          {TRANSFER_STATUS_DATA.filter((x) =>
                            login.company.master ? true : x.corporate
                          ).map((x) => (
                            <Select.Option value={x.code}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Data da Nota Fiscal"
                        name="dateNF"
                        rules={[
                          { required: false, message: "Insira uma data" }
                        ]}
                      >
                        <DatePicker
                          disabled={!canEdit}
                          format="YYYY-MM-DD HH:mm:ss"
                          showTime
                        />
                      </Form.Item>
                    </Col>
                    {login.company.master && (
                      <React.Fragment>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="CPF"
                            name="cpf"
                            rules={[
                              {
                                required: false,
                                message: "Insira um CPF válido"
                              }
                            ]}
                          >
                            <InputMask
                              mask="999.999.999-99"
                              placeholder="000.000.000-00"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="CNPJ"
                            name="cnpj"
                            rules={[
                              {
                                required: false,
                                message: "Insira um CNPJ válido"
                              }
                            ]}
                          >
                            <InputMask
                              addonAfter={
                                <SearchOutlined
                                  onClick={() => searchCompany()}
                                />
                              }
                              mask="99.999.999/9999-99"
                              placeholder="99.999.999/9999-99"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item label="CNAE" name="cnae">
                            <Input autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col md={24}>
                          <Form.Item
                            label="Nome Empresarial"
                            name="razaoSocial"
                            rules={[
                              {
                                required: false,
                                message: "Insira um nome empresarial"
                              }
                            ]}
                          >
                            <Input autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col md={24}>
                          <Form.Item
                            label="Nome Real"
                            name="nomeFantasia"
                            rules={[
                              {
                                required: false,
                                message: "Insira um nome real"
                              }
                            ]}
                          >
                            <Input autoComplete="off" />
                          </Form.Item>
                        </Col>
                      </React.Fragment>
                    )}
                    <Col md={24}>
                      <Form.Item label="Mensagem" name={"detalhes"}>
                        <Input.TextArea
                          disabled={!canEdit}
                          rows={3}
                          autoComplete="off"
                          placeholder="Caso não aprove o reembolso, pode deixar uma mensagem para o colaborador."
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Form>
        <Modal
          title="Pesquisa CNPJ na Receita Federal"
          open={showSearch}
          onOk={onParseSearch}
          onCancel={() => onShowSearch(false)}
        >
          <Typography.Text>
            Copie e cole o comprovante de inscrição no campo a baixo para
            leitura dos dados.
          </Typography.Text>
          <Input.TextArea
            rows={5}
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
          />
        </Modal>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          disabled={loading}
          loading={loading}
          type="primary"
          icon={<SaveOutlined />}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(RefundDetailsScreen);
