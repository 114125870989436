import React, { useRef } from "react";
import { connect } from "react-redux";
import {
  Layout,
  Table,
  Typography,
  Space,
  Tag,
  Tooltip,
  Button,
  Modal,
  Row,
  Col,
  Avatar,
} from "antd";
import {
  InfoCircleOutlined as InfoCircle,
  TransactionOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  batchTransferTransfering,
  batchTransferConfirming,
  getAllTransfer,
  retryTransfer,
} from "services/transfer-service";
import { FormatDate, FormatMoney, TableSearch } from "components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatCpf, formatMoney, getMaskedCpf } from "utils";
import lodash from "lodash";
import { useOutletContext } from "react-router-dom";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
});

const DataFetch = {
  data: [],
  loading: false,
};

const TransferScreen = (props) => {
  const { login } = props;
  const { onRefreshBalance, balance } = useOutletContext();
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [loadingBatch, setLoadingBatch] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [defaultFilter, setDefaultFilter] = React.useState(["v"]);
  const hasSearched = useRef(false);

  const checkBatchTransfering = React.useMemo(
    () => data.data.filter((x) => x.status.code === "c").length > 0,
    [data],
  );
  const checkBatchValidation = React.useMemo(
    () => data.data.filter((x) => x.status.code === "v").length > 0,
    [data],
  );

  const load = async (date = undefined) => {
    if(!hasSearched.current){
      hasSearched.current = true
    }
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllTransfer(date);
    setData({ data: res, loading: false });
    onRefreshBalance();
  };

  const onRetry = async () => {
    setLoadingBatch(true);
    const res = await retryTransfer(selectedRowKeys);
    setSelectedRowKeys([]);
    await load();
    onConfirmMessage(res);
    setLoadingBatch(false);
  };

  const onBatchPendents = async () => {
    setLoadingBatch(true);
    const res = await batchTransferConfirming();
    await load();
    onConfirmMessage(res);
    setLoadingBatch(false);
  };

  const onBatchConfirmed = async () => {
    setLoadingBatch(true);
    const res = await batchTransferTransfering();
    await load();
    //onConfirmMessage(res);
    setLoadingBatch(false);
  };

  const onConfirmMessage = (res) => {
    if (res) {
      Modal.success({
        width: 530,
        title: (
          <Typography.Title level={4}>Totais Transferências</Typography.Title>
        ),
        content: (
          <React.Fragment>
            <Row justify="space-between">
              <Col>
                <Typography.Title level={5}>Bem-sucedidas:</Typography.Title>
              </Col>
              <Col>
                <Typography.Title level={5}>
                  <strong>
                    {formatMoney(res.total)} / {res.count}
                  </strong>
                </Typography.Title>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Typography.Title level={5}>Com erros:</Typography.Title>
              </Col>
              <Col>
                <Typography.Title level={5}>
                  <strong>
                    {formatMoney(res.totalErrors)} / {res.countErrors}
                  </strong>
                </Typography.Title>
              </Col>
            </Row>
          </React.Fragment>
        ),
      });
    }
  };

  React.useEffect(() => {
    if(!hasSearched.current){
      load();
    }
  }, []);

  React.useEffect(() => {
    if (data.data.length > 0) {
      if (checkBatchTransfering) {
        setDefaultFilter(["c"]);
      } else if (checkBatchValidation) {
        setDefaultFilter(["v"]);
      } else {
        setDefaultFilter([]);
      }
    }
  }, [data]);

  return (
    <>
      <Content>
        <div style={{padding:"16px 16px"}}>
          <TableSearch
            request={load}
            type="date"
            data={data.data}
            onSearch={e => {
              setData(prev => ({ ...prev, data: e }));
            }}
          />
        </div>
        <Table
          rowKey={"id"}
          scroll={{ y: "50vh" }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: setSelectedRowKeys,
            hideSelectAll: false,
            getCheckboxProps: row => ({
              disabled: row.pixAlert && row.validPix ? false : true
            })
          }}
          dataSource={data.data}
          loading={data.loading}
          pagination={false}
          bordered
          onChange={(pagination, filters, sorter) =>
            setDefaultFilter(prev =>
              filters.status && filters.status.length > 0 ? [filters.status[0]] : []
            )
          }
        >
          <Table.Column
            width={150}
            align={"center"}
            title="Data"
            dataIndex="createdAt"
            key="createdAt"
            render={value => <FormatDate value={value} />}
          />
          <Table.Column
            title="Colaborador"
            dataIndex="employee"
            key="employee"
            render={(value, row) => (
              <React.Fragment>
                <Space>
                  {value}
                  {row.updatedEmployee && (
                    <Tooltip
                      placement="bottom"
                      title={`Usuário atualizado em ${moment(row.dateUpdatedUser).format(
                        "DD/MM/YYYY HH:mm"
                      )}`}
                    >
                      <InfoCircle />
                    </Tooltip>
                  )}
                </Space>
              </React.Fragment>
            )}
          />

          <Table.Column
            title="CPF"
            dataIndex="employeeCpf"
            key="employeeCpf"
            render={value => getMaskedCpf(value)}
          />
          <Table.Column
            width={250}
            title="Empresa"
            dataIndex="employeeCompany"
            key="employeeCompany"
          />
          <Table.Column
            width={250}
            title="PIX"
            dataIndex="pixKey"
            key="pixKey"
            render={(value, row) => (
              <Row justify="space-between">
                <Col>{value}</Col>
                <Col>
                  {row.pixAlert && (
                    <Tooltip title={row.pixAlert.message}>
                      <Avatar
                        className={`bg-${row.pixAlert.category.toLowerCase()}`}
                        size="small"
                        icon={<InfoCircle />}
                      />
                    </Tooltip>
                  )}
                </Col>
              </Row>
            )}
          />
          <Table.Column
            width={150}
            align={"right"}
            title="Valor"
            dataIndex="value"
            key="value"
            render={value => <FormatMoney value={value} />}
          />
          <Table.Column
            width={90}
            align={"right"}
            title="Taxa"
            dataIndex="fee"
            key="fee"
            render={value => <FormatMoney value={value} />}
          />
          <Table.Column
            width={200}
            align={"center"}
            title="Status"
            dataIndex="status"
            key="status"
            filters={lodash.uniqWith(
              data.data.map(d => {
                return { text: d.status.name, value: d.status.code };
              }),
              lodash.isEqual
            )}
            onFilter={(value, record) => record.status.code === value}
            filteredValue={defaultFilter}
            filterMultiple={false}
            render={value => <Tag color={value.category}>{value.name.toUpperCase()}</Tag>}
          />
        </Table>
      </Content>
      {data.data.length > 0 && (
        <Footer>
          <Row justify="space-between" style={{ width: "100%" }}>
            <Col>
              <Button
                loading={loadingBatch}
                disabled={selectedRowKeys.length === 0}
                type="primary"
                icon={<ReloadOutlined />}
                onClick={onRetry}
              >
                Adicionar Re-Tentativas no Lote
              </Button>
            </Col>
            <Col>
              <Space>
                <Button
                  disabled={!checkBatchValidation}
                  loading={loadingBatch}
                  type="primary"
                  icon={<TransactionOutlined />}
                  onClick={onBatchPendents}
                >
                  Adicionar Transferências em 'Validação' no Lote
                </Button>
                {checkBatchTransfering && (
                  <Button
                    danger
                    disabled={balance.transfer?.total > balance.balance}
                    loading={loadingBatch}
                    type="primary"
                    icon={<TransactionOutlined />}
                    onClick={onBatchConfirmed}
                  >
                    Processar Lote de Transferências
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Footer>
      )}
    </>
  );
};

export default connect(mapStateToProps, {})(TransferScreen);
